import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiAutomationsFetch } from '@/services/DataService'; // Update the service function name
import { setAutomations, setLoading, setError, AutomationsState } from '@/store/slices/data/automationsSlice'; // Update the slice import
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const useAutomationsFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const automationsState = useAppSelector((state) => state.data.automationsState) // Update the state selector

    const fetchAutomations = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiAutomationsFetch(); // Update the service call
            dispatch(setAutomations(response.data[0])); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && automationsState.automations?.length === 0) { // Update the condition
            fetchAutomations();
        }
    }, [dispatch, authenticated, automationsState.automations?.length]); // Update the dependency

    return automationsState as AutomationsState; // Update the return type
};

export default useAutomationsFetch;
